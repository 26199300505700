import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Who = () => (
  <Layout>
    <SEO title="Who we are" />
    <div className="wrapper">
      <div className="title">
        <h2 id="whowe">Who We Are</h2>
        <h1 id="whatwe">and What We Do</h1>
      </div>
      <hr />
      <div className="wwa">
        <ul className="wwat">
          <li className="text">
            <strong>Evangelism:</strong> The Gospel taken outside the four walls
            is the absolute foundation of our church, in obedience to Mark 16:15
            And He said to them, ‘Go into all the world and preach the gospel to
            every creature'… We emphasize personal evangelism, and a large
            variety of outreaches to share the Gospel.
          </li>
          <li className="text">
            <strong>Conversion:</strong> We witness, testify and preach for a
            decision. The Gospel has the power to save and transform lives. 1
            Peter 1:23 having been born again, not of corruptible seed but
            incorruptible, through the word of God which lives and abides
            forever… The bulk of our congregation consists of people converted
            here by the Power of God.
          </li>
          <li className="text">
            <strong>The Local Church:</strong> God has placed everything
            necessary to accomplish His will in the setting of the local church.
            Ephesians 1:22-23 …the church. Which is his body, the fullness of
            him who fills everything in every way. God’s will is accomplished in
            us and through us as connect and commit ourselves to a local church.
          </li>
          <li className="text">
            <strong>Discipleship:</strong> God intends for calling to Him, and
            for Him, to be accomplished through being trained and equipped
            within the setting of a local church. Therefore, the highest calling
            of a Pastor and congregation is to obey God’s command in Matthew
            28:19-20 Go therefore and make disciples of all the nations,
            baptizing them in the name of the Father and of the Son and of the
            Holy Spirit, teaching them to observe all things that I have
            commanded you; and lo, I am with you always, even to the end of the
            age." Amen.
          </li>
          <li className="text">
            <strong>Church Planting:</strong> The result of disciples being
            raised up is that they be released into the harvest field. Matthew
            9:37-38 Then He said to His disciples, "The harvest truly is
            plentiful, but the laborers are few. 38 Therefore pray the Lord of
            the harvest to send out laborers into His harvest." We send couples
            to plant brand new congregations, with the aim of reproducing our
            vision in a new area.
          </li>
          <li className="text">
            <strong>World Evangelism:</strong> From the earliest Bible record,
            God’s vision is worldwide testimony and impact. The gospel message
            and the vision God has revealed to us is not simply American, but
            God-inspired; so therefore it works, and is relevant in every nation
            and culture of the world. Mark 16:15 all the world/every
            person…Matthew 28:19 all the nations… Our job as believers, and as
            the local church is to pray, train, invest, send and support workers
            to go into all the world!
          </li>
        </ul>
      </div>
    </div>
  </Layout>
)

export default Who
